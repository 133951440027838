$light: 300;
$regular: 400;
$semibold: 600;
$black: 900;

$roboto: 'Roboto', sans-serif;

$border-radius: 28px;

$f-regular: 12px;
$f-xmedium: 14px;

$primary: #009CD7;
$secondary: #2F3068;
$highlight: #765BA7;
$orange: #FBAD18;
$help: #ABABAB;
$grey: #c7c7c7;
$bg: #f6f8fa;
$white: #ffffff;
$black: #222222;
$border: #eee;
