// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
@import "./assets/scss/variables.scss";
@import "./assets/fonts/fonts.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cuponeala-primary: mat-palette($mat-indigo);
$cuponeala-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cuponeala-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cuponeala-theme: mat-light-theme(
  (
    color: (
      primary: $cuponeala-primary,
      accent: $cuponeala-accent,
      warn: $cuponeala-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($cuponeala-theme);

/* You can add global styles to this file, and also import other style files */

:focus-visible {
  outline: 0 !important;
}

* {
  scroll-margin-top: 80px;
}
html,
body {
  height: 100%;
}
body {
  background: $bg;
  margin: 0;
  font-family: "Gilroy-Medium";
}

.pointer {
  cursor: pointer;
}

.logo,
.logo-footer {
  max-width: 150px;
  img {
    width: 100%;
  }
}

input,
textarea {
  &:focus {
    outline: 0 !important;
    border-color: red !important;
    box-shadow: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

button {
  &:focus {
    outline: 0;
  }
  &.mat-raised-button {
    border-radius: 8px;
    font-family: "Gilroy-Medium";
    &.mat-primary {
      background-color: #009cd7;
      color: #000000;
      padding: 4px 25px;
      border-radius: 8px;
      border: none;
      font-size: 14px;
      font-family: Gilroy-Medium;
    }
    &.mat-warn {
      background: #765ba7 !important;
      color: #fff;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: normal;
      padding: 6px 30px;
      font-family: Gilroy-Medium;
    }
    &.mat-accent {
      background-color: #2f3068;
      text-transform: capitalize;
      font-size: 14px;
      color: #fff;
      font-weight: 300;
    }
  }
  &.mat-basic {
    background-color: #fcbc00;
    color: #fff;
  }
  &.mat-stroked-button {
    font-family: "Gilroy-Medium";
    &.mat-primary {
      color: #fff;
      border-color: #fff;
    }
  }
}

.errorBox{
  color: #721C23;
  background-color: #F8D7DA;
  border:solid 1px #F5C6CA; 
  width: 100%;
  display: block;
}
.resultBox{
  color: #1c7240 !important;
  background-color: #d7f8e5;
  border:solid 1px #d7f8e5; 
  border-radius: 10px;
  width: 100%;
  display: block;
  padding: 10px
}

mat-form-field {
  font-family: "Gilroy-Medium";
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #007bff;
}

.dialog {
  &.discount {
    
    mat-dialog-container {
      background: #0033ff;
      
      padding: 24px !important;
  }}
  &.resetPassword {
    mat-dialog-container {
      background: transparent;
      padding: 24px !important;
      border-radius: 8px;
      box-shadow: none;
      p {
        color: #fff;
      }
    }
  }
  mat-dialog-container {
    padding: 0 !important;
  }
}
.gm-style-iw {
  max-width: 360px !important;
  max-height: initial !important;
  height: 218px;
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    width: 18px;
    height: 12px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.12);
    border: 6px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-track-piece {
    background: #fff;
  }
  &::-webkit-scrollbar-track-piece {
    background: #fff;
  }

  .gm-style-iw-d {
    max-height: initial !important;
  }
}

.dialog {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: none;
    border-radius: initial;
  }
}

@media (min-width: 1199px) {
  .coupon-container-special {
    max-width: 510px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media screen and (max-width: 767px) {
  .cdk-overlay-container {
    z-index: 9999999999;
  }
  .owl-nav {
    display: none;
  }
  .cdk-overlay-container {
    z-index: 999999;
  }
  .dialog {
    max-width: 100% !important;
    &.coupon {
      height: 100%;
    }
  }
}